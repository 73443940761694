exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-services-js": () => import("./../../../src/pages/about-us/services.js" /* webpackChunkName: "component---src-pages-about-us-services-js" */),
  "component---src-pages-about-us-team-js": () => import("./../../../src/pages/about-us/team.js" /* webpackChunkName: "component---src-pages-about-us-team-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-templates-bios-js": () => import("./../../../src/templates/bios.js" /* webpackChunkName: "component---src-templates-bios-js" */)
}

